.flex {
  display: flex;
}

.flex-0	{
  flex: 0 0 0;
}
.flex-1	{
  flex: 1 1 0;
}

.flex-2	{
  flex: 2 2 0;
}

.flex-3	{
  flex: 3 3 0;
}

.flex-4	{
  flex: 4 4 0;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.items {
  &-start {
    align-items: start;
  }

  &-center {
    align-items: center;
  }

  &-end {
    align-items: end;
  }
}

.self {
  &-start {
    align-self: start;
  }

  &-center {
    align-self: center;
  }

  &-end {
    align-self: end;
  }

  &-stretch {
    align-self: stretch;
  }
}

.justify {
  &-start {
    justify-content: start;
  }

  &-center {
    justify-content: center;
  }

  &-end {
    justify-content: end;
  }

  &-between {
    justify-content: space-between;
  }

  &-around {
    justify-content: space-around;
  }
}
